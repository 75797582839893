<template>
	<div class="car-id">
		<Header></Header>
		<div class="wrapper" style="min-height: 738px;">
			<div class="search shadow">
				<span class="title">筛选：</span>
				<el-select v-model="search.fleet_id" placeholder="请选择车队" clearable>
					<el-option v-for="item in fleetList" :key="item.id" :label="item.name" :value="item.id">
					</el-option>
				</el-select>
				<el-input class="search-input" @keyup.enter.native="GetList()" clearable v-model="search.keyword" placeholder="请输入车牌号或车架号进行模糊搜索"></el-input>
				<el-button type="primary" @click="GetList()">搜索</el-button>
			</div>
			<div class="main-title">
				车辆列表
				<router-link target="_blank" v-if="stepInsurance" :to="{name:'addTruck'}">
					<el-button type="primary">新建车辆</el-button>
				</router-link>
			</div>
			<el-table :data="listData" stripe style="width: 100%;">
				<el-table-column fixed prop="mplan_new" :formatter="mplanNewFormat" label="类型" width="100">
				</el-table-column>
				<el-table-column fixed label="车牌号" width="170">
					<template slot-scope="{row}">
						<span>{{row.number}}</span>
						<img src="../../assets/img/new.png" v-if="row.mplan_new == 1" class="new-icon" />
						<img src="../../assets/img/bao.png" v-if="row.mplancpic == 1" class="bao-icon" />
					</template>
				</el-table-column>
				<el-table-column fixed prop="vin" label="底盘号" width="110"></el-table-column>
				<el-table-column prop="brand" label="品牌型号" width="110"></el-table-column>
				<el-table-column prop="owner" label="车主姓名" width="140"></el-table-column>
				<el-table-column prop="phone" label="手机号" width="140"></el-table-column>
				<el-table-column prop="mileage" label="行驶里程(km)" width="120"></el-table-column>
				<el-table-column prop="next_mileage" label="下次保养里程(km)" width="120"></el-table-column>
				<el-table-column prop="maintainDate" label="下次保养日期" width="110"></el-table-column>
				<el-table-column prop="insuranceDate" label="保险到期日期" width="110"></el-table-column>
				<el-table-column prop="inspectionDate" label="年审到期日期" width="110"></el-table-column>
				<el-table-column fixed="right" label="状态" width="250">
					<template slot-scope="{row}">
						<el-steps v-if="row.mplan_new == 1" :active="row.mplan_step" align-center
							finish-status="success">
							<el-step title="添加车辆"></el-step>
							<el-step :title="row.mplancpic == 1 ? '加装设备':'无需加装'"></el-step>
							<el-step :title="row.mplancpic == 1 ? '用户提车':'无需提车'"></el-step>
						</el-steps>
						<el-steps v-if="row.mplan_new == 0" :active="row.mplan_step" align-center
							finish-status="success">
							<el-step title="添加车辆"></el-step>
							<el-step :title="row.mplancpic == 1 ? '车辆检测':'无需检测'"></el-step>
							<el-step :title="row.mplancpic == 1 ? '加装设备':'无需加装'"></el-step>
							<el-step :title="row.mplancpic == 1 ? '用户提车':'无需提车'"></el-step>
						</el-steps>
					</template>
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="360">
					<template slot-scope="{row}">
						<el-button v-if="stepAddDevice && row.mplan_new == 1 && row.mplan_step == 1" type="primary" size="small"
							@click="handleAddDevice(row)">加装设备</el-button>
						<el-button v-if="stepSent && row.mplan_new == 1 && row.mplan_step == 2" type="primary" size="small"
							@click="handleSent(row)">用户提车</el-button>
						<el-button v-if="stepAddDevice && row.mplan_new == 0 && row.mplan_step == 2" type="primary" size="small"
							@click="handleAddDevice(row)">加装设备</el-button>
						<el-button v-if="stepSent && row.mplan_new == 0 && row.mplan_step == 3" type="primary" size="small"
							@click="handleSent(row)">用户提车</el-button>
						<el-button v-if="!stepEdit" @click="handleClick(row.id)" type="primary" size="small">查看</el-button>
						<el-button v-if="stepEdit" @click="handleClick(row.id)" type="primary" size="small">编辑</el-button>
						<el-button v-if="canAddCheck(row, stepAddDevice)" @click="addCheck(row)" type="primary" size="small">新增养护</el-button>
						<el-button @click="testingClick(row.id)" type="primary" size="small">养护记录</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- <div class="car-list">
				<ul>
					<li class="shadow" v-for="(item) in listData" :key="item.id" @click="CarIdClick(item)">
						<div class="left">
							<i class="el-icon-truck"></i>
							<span>{{item.number}}</span>
						</div>
						<div class="right">
							<span v-if="item.next_mileage - item.mileage > 0" class="next">下次保养：<i>{{item.next_mileage - item.mileage}}</i>km</span>
							<el-button v-if="item.passed == 9" type="primary" plain size="mini">合格({{item.passed}}/9)</el-button>
							<el-button v-if="item.passed < 9" type="danger" plain size="mini">合格({{item.passed}}/9)</el-button>
							<el-button type="primary" plain size="mini">车检({{item.checks}})次</el-button>
							<i class="el-icon-arrow-right"></i>
						</div>
					</li>
				</ul>
			</div> -->
			<el-pagination background layout="prev, pager, next" :page-size="search.size"
				:current-page.sync="search.page" :pager-count="5" :total="total" @size-change="handleSizeChange"
				@current-change="handleCurrentChange">
			</el-pagination>

			<el-dialog title="加装设备" :visible.sync="dialogAddDeviceShow">
				<el-form ref="addDeviceForm" :rules="addDeviceRules" :model="car" label-position="left"
					label-width="150px" style="width: 400px; margin-left:50px;">
					<el-form-item label="设备" prop="addDevice">
						<el-radio label="1" v-model="car.addDevice">确认已加装</el-radio>
					</el-form-item>
					<el-form-item label="下次保养里程数" prop="nextMileage">
						<el-input v-model="car.nextMileage" />
					</el-form-item>
					<el-form-item label="保养到期日期" prop="maintainDate">
						<el-date-picker v-model="car.maintainDate" type="date" value-format="yyyy-MM-dd"
							placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="加装人员" prop="mplanAddDeviceOperator">
						<el-input v-model="car.mplanAddDeviceOperator" />
					</el-form-item>
					<el-form-item label="工时" prop="mplanAddDeviceHour">
						<el-input v-model="car.mplanAddDeviceHour" oninput="value=value.replace(/[^\d]/g, '')" />
					</el-form-item>
					<el-form-item label="备注" prop="mplanAddDeviceRemark">
						<el-input v-model="car.mplanAddDeviceRemark" />
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button @click="dialogAddDeviceShow = false">
						取消
					</el-button>
					<el-button type="primary" @click="submitAddDevice">
						提交
					</el-button>
				</div>
			</el-dialog>

			<el-dialog title="用户提车" :visible.sync="dialogSentShow">
				<el-form ref="sentForm" :model="car" :rules="sentRules" label-position="left" label-width="150px" style="width: 400px; margin-left:50px;">
					<el-form-item label="提车" prop="sent">
						<el-radio label="1" v-model="car.sent">确认已提车</el-radio>
					</el-form-item>
					<el-form-item label="备注" prop="mplanSentRemark">
						<el-input v-model="car.mplanSentRemark" />
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button @click="dialogSentShow = false">
						取消
					</el-button>
					<el-button type="primary" @click="submitSent">
						提交
					</el-button>
				</div>
			</el-dialog>

			<el-dialog title="新增养护" :visible.sync="dialogCheckShow">
				<el-form ref="checkForm" :model="check" :rules="checkRules" label-position="left" label-width="150px" style="width: 400px; margin-left:50px;">
					<el-form-item label="维修工" prop="userName">
						<el-input v-model="check.userName" placeholder="请输入维修工姓名" />
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button @click="dialogCheckShow = false">
						取消
					</el-button>
					<el-button type="primary" @click="submitCheck">
						提交
					</el-button>
				</div>
			</el-dialog>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import http from '../../http.js'
	import Header from "../../components/header.vue"
	import Footer from "../../components/footer.vue"
	export default {
		name: 'Home',
		components: {
			Header,
			Footer
		},
		data() {
			return {
				stepInsurance: false,
				stepAddDevice: false,
				stepSent: false,
				stepEdit: false,
				fleetName: '',
				fleetList: [],
				listData: [],
				total: 0,
				search: {
					keyword: '',
					fleet_id: '',
					status: '',
					page: 1,
					size: 10,
				},
				dialogAddDeviceShow: false,
				dialogSentShow: false,
				car: {
					sent: '',
					addDevice: '',
					nextMileage: '',
					maintainDate: '',
					mplanAddDeviceRemark: '',
					mplanSentRemark: '',
					mplanAddDeviceOperator: '',
					mplanAddDeviceHour: ''
				},
				addDeviceRules: {
					addDevice: [{
						required: true,
						message: '请确认是否已加装设备',
						trigger: 'blur'
					}],
					nextMileage: [{
						required: true,
						message: '请输入下次保养里程',
						trigger: 'blur'
					}],
					maintainDate: [{
						required: true,
						message: '请输入下次保养日期',
						trigger: 'change'
					}],
				},
				sentRules: {
					sent: [{
						required: true,
						message: '请确认用户已提车',
						trigger: 'blur'
					}]
				},
				checkRules: {
					userName: [{
						required: true,
						message: '请输入维修工姓名',
						trigger: 'blur'
					}]
				},
				dialogCheckShow: false,
				check: {
					id: 0,
					carId: 0,
					checkTime: '',
					details: [],
					files0: [],
					files1: [],
					upfiles0: [],
					upfiles1: [],
					status: 0,
					userName: ''
				}
			}
		},
		created() {
			for (let i = 1; i <= 8; i++) {
				this.check.details.push({
					itemId: i,
					files: [],
					upfiles: []
				})
			}
			if (this.$route.query.keyword) {
				this.search.keyword = this.$route.query.keyword
			}
			if (this.$route.query.fleetId) {
				this.search.fleet_id = Number(this.$route.query.fleetId)
			}
			let roles = localStorage.getItem('roles').split(',')
			if(roles.indexOf('mplan-rent') > -1){
				this.stepInsurance = true
			}
			if(roles.indexOf('mplan-service') > -1){
				this.stepAddDevice = true
			}
			if(roles.indexOf('mplan-sell') > -1){
				this.stepSent = true
			}
			if(roles.indexOf('mplan-edit') > -1){
				this.stepEdit = true
			}
			this.getFleetList()
			this.GetList()
		},
		methods: {
			testingClick(id){
				const routeData = this.$router.resolve("/typeSelect?carId=" + id)
				window.open(routeData.href, '_blank')
			},
			getFleetList() {
				http.fetchGet('/api/admin/fleet/list', {
					page: 1,
					pagesize: 1000
				}).then((res) => {
					if (res.data.code == 0) {
						this.fleetList = res.data.data.list
					}
				})
			},
			handleClick(id) {
				const routeData = this.$router.resolve("/addTruck?id=" + id)
				window.open(routeData.href, '_blank')
			},
			CarIdClick(item) {
				this.$router.push("/typeSelect?carId=" + item.id + "&carNumber=" + item.number)
			},
			GetList() {
				http.fetchGet('/api/v2/admin/car-check/car/list', this.search).then((res) => {
					if (res.data.code == 0) {
						this.listData = res.data.data.list
						this.total = res.data.data.total
					} else {
						this.$message.error(res.data.desc)
					}
				})
			},
			handleSizeChange() {
				this.GetList()
			},
			handleCurrentChange() {
				this.GetList()
			},
			mplanNewFormat(row) {
				return row.mplan_new == 1 ? '新车' : '运营中车辆'
			},
			handleAddDevice(row) {
				this.car.id = row.id
				this.car.addDevice = ''
				this.car.nextMileage = row.next_mileage
				this.car.mplanAddDeviceRemark = row.mplan_add_device_remark
				this.car.maintainDate = row.maintain_date
				this.dialogAddDeviceShow = true
			},
			handleSent(row) {
				this.car.id = row.id
				this.car.mplanSentRemark = row.mplan_sent_remark
				this.dialogSentShow = true
			},
			submitAddDevice() {
				this.$refs['addDeviceForm'].validate((valid) => {
					if (valid) {
						http.fetchPost('/api/admin/mplan/add-device', this.car).then((res) => {
							if (res.data.code == 0) {
								this.GetList()
								this.dialogAddDeviceShow = false
								this.$message.success('操作成功')
							} else {
								this.$message.error(res.data.desc)
							}
						})
					}
				})
			},
			submitSent() {
				this.$refs['sentForm'].validate((valid) => {
					if (valid) {
						http.fetchPost('/api/admin/mplan/sent', this.car).then((res) => {
							if (res.data.code == 0) {
								this.GetList()
								this.dialogSentShow = false
								this.$message.success('操作成功')
							} else {
								this.$message.error(res.data.desc)
							}
						})
					}
				})
			},
			addCheck(item){
				this.check.carId = item.id
				this.check.userName = ''
				this.dialogCheckShow = true
			},
			submitCheck() {
				this.$refs['checkForm'].validate((valid) => {
					if (valid) {
						http.fetchPost('/api/admin/mplan/car_check', this.check).then((res) => {
							if (res.data.code == 0) {
								this.dialogCheckShow = false
								this.$message.success('添加成功')
							} else {
								this.$message.error(res.data.desc)
							}
						})
					}
				})
			},
			canAddCheck(car, power){
				if(power){
					return true
					// if(car.mplan_new == 1 && car.mplan_status == 1){
					// 	return true
					// }
					// if(car.mplan_new == 0 && car.mplan_step >= 1){
					// 	return true
					// }
				}
				return false
			}
		}
	}
</script>
<style lang="scss">
	.el-step__title {
		font-size: 14px;
	}
</style>
